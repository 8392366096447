@use 'cosmos/scss/theme-styles/customer-portal';
@use './components';
@use '@cosmos/scss/global';
@use 'tailwindcss/components' as tw_components;
@use 'tailwindcss/utilities' as tw_utilities;

body {
  margin: 0;

  @media screen {
    background-color: theme('colors.base.200');
  }

  @media print {
    background-color: theme('colors.base.100');
  }
}

// TODO: Find better place for that style

@include global.wider-than-or-equal(theme('screens.md')) {
  #AcceptUIContainer {
    top: 30% !important;
  }
}
