@use '../settings' as s;

:root {
  --divider-text-color: var(--base-500);
  --divider-color: var(--base-300);
}

// temporary, until tw utils
.cos-card {
  hr.card-divider {
    margin: theme('spacing.4') -16px;
  }
}

hr {
  border: none;
  height: 0;
  margin: theme('spacing.4') 0;
  padding: 0;
  border-top: 1px solid var(--divider-color);
}

.cos-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  margin: theme('spacing.2') 0;
  height: 0.0625rem;
  min-height: 0.0625rem;
  white-space: nowrap;
  color: var(--divider-text-color);

  &::before,  &::after {
    content: '';
    flex-grow: 1;
    height: 0.0625rem;
    width: 100%;
    color: var(--divider-color);
    background-color: var(--divider-color);
  }

  &-dashed {
    &::before,    &::after {
      height: 0;
      background: none;
      border-top: 1px dashed var(--divider-color);
    }
  }

  &:not(:empty) {
    gap: theme('spacing.2');
    height: 1rem;
  }

  &-horizontal {
    flex-direction: column;
    margin: 0 theme('spacing.4');
    height: auto;
    min-width: 0.0625rem;

    &::before,    &::after {
      height: 100%;
      width: 0.0625rem;
    }

    &.cos-divider-dashed {
      &::before,      &::after {
        width: 0;
        background: none;
        border-top: none;
        border-left: 1.5px dashed var(--divider-color);
      }
    }

    &:not(:empty) {
      gap: theme('spacing.1');
      height: auto;
    }
  }
}
