@use './esp' as t;
@use './utils' as utils;

@mixin cos-theme-customer-portal {
  @include t.cos-theme-esp-font; // Take base from ESP+ light theme
  @include t.cos-theme-esp-base; // Take base from ESP+ light theme
  @include utils.cos-theme-rgb-variables; // Set RGB variables
  @include cos-theme-customer-portal-overrides;
}

@mixin cos-theme-customer-portal-classes {
  @include utils.cos-theme-classes('customer-portal');
}

@mixin cos-theme-customer-portal-overrides {
  /* Global Header */
  --global-header__bg: theme('colors.base.100');
  --global-header-nav__bg: theme('colors.base.100');
  --global-header-nav__color: var(--base-content-low);
  --global-header-nav__hover__bg: var(--base-200);
  --global-header-nav__hover__color: var(--base-content-low);
}
