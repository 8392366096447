@use '../settings' as s;

.mat-menu-panel {
  background: var(--base-100);
  border-radius: theme('borderRadius.DEFAULT');
  box-shadow: theme('boxShadow.menu') !important;

  .mat-active,  .mat-mdc-option:hover {
    background: var(--base-200);
  }
}

.mat-menu-panel.cos-menu--full-width {
  max-width: 100% !important;
  width: 100%;
}

.cos-backdrop--full-width
  + .cdk-overlay-connected-position-bounding-box
  .cdk-overlay-pane {
  width: 100% !important;
}

.cos-global-menu-backdrop + .cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane {
    // These first three lines are duplicates of Material menu css for desktop.
    // Copying them here so they will also apply to mobile.
    border-radius: theme('borderRadius.DEFAULT');
    width: auto !important;
    height: auto !important;
    margin-top: theme('spacing.2');
  }
}

.cos-global-mat-menu {
  &.mat-menu-above {
    margin-bottom: theme('spacing.4');
  }
}

.cos-global-menu-panel {
  min-width: 11.88rem;
}

.mat-menu-panel {
  min-height: 0rem !important;
  .mat-menu-item {
    background-color: transparent;
    position: relative;
    height: auto;

    @apply text-body-xs--light;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    // padding: 0 theme('spacing.3');
    line-height: theme('lineHeight.6');
    background: var(--menu-item__bg, transparent);
    color: var(--menu-item__color, var(--base-content-low));

    &:disabled,    &[disabled] {
      background: var(--menu-item__disabled__bg, transparent);
      color: var(--menu-item__disabled__color, var(--base-400));
    }

    > i {
      flex: 0 0 theme('spacing.3');
      margin-right: theme('spacing.3');
      font-size: theme('fontSize.11');
      text-align: center;
      min-width: 0.6875rem;
    }

    &.cos-menu-item--unique {
      height: 2.25rem !important;
      margin: theme('spacing.2') 0;
      border-top: theme('borders.light');
      border-bottom: theme('borders.light');
      line-height: theme('lineHeight.9');
      background-color: var(--menu-item__unique__bg, var(--base-200));
      color: var(--menu-item__unique__color, var(--danger-500));

      &:first-child {
        margin-top: calc(-1 * theme('spacing.1'));
      }

      &:last-child {
        margin-bottom: calc(-1 * theme('spacing.2'));
        border-bottom: none;
      }

      &:hover {
        background-color: var(--menu-item__unique__hover__bg, var(--base-100));
        color: var(--menu-item__unique__hover__color, var(--danger-500));
      }
    }

    &:hover {
      text-decoration: none;
      background-color: var(--menu-item__hover__bg, var(--base-200));
      color: var(--menu-item__hover__color, var(--base-content-low));
    }

    &:focus-visible {
      @include s.cos-focus-inset();
      border-radius: theme('borderRadius.DEFAULT');
    }

    &.link-menu-item {
      margin: 0;
      padding: 0;
      line-height: 1;
    }
  }

  $avatar-size: 2.5rem;

  .global-menu-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: -0.1875rem -0.0625rem 0.4rem;
    padding: 0.1875rem 0.0625rem;

    &__emoji {
      background-color: var(--base-200);
      margin-right: 0.6rem;
      width: $avatar-size;
      height: $avatar-size;
      display: flex;
      border: none;
      justify-content: center;
      align-items: center;
      flex-basis: $avatar-size;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &__icon {
      border: none;
      min-width: $avatar-size;
      min-height: $avatar-size;
      max-width: $avatar-size;
      max-height: $avatar-size;
      margin-right: theme('spacing.2');
      border-radius: theme('borderRadius.sm') !important;

      & img {
        display: block;
        max-width: $avatar-size;
        max-height: $avatar-size;
        border-radius: theme('borderRadius.sm') !important;
      }
    }

    h4,    p {
      margin-bottom: 0;
    }

    &__content {
      min-width: 0;
      display: flex;
      flex-direction: column;
    }
  }
}
