@use '@cosmos/scss/settings' as s;

.info-grid {
  @apply text-body-xs--light;

  display: grid;
  flex-direction: row;
  row-gap: theme('spacing.2');
  padding: 0;
  margin: 0;
}

.info-grid__item {
  display: grid;
  grid-template-columns: 1.563rem 1fr;
  flex-direction: column;
  align-items: baseline;

  > i {
    justify-self: center;
    margin-right: theme('spacing.[2.5]');
  }
}
