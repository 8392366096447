@use '@cosmos/scss/settings' as s;

.cos-clickable-host {
  display: block;

  &--clickable {
    @apply cursor-pointer;

    &:focus-within,    &:focus-visible {
      outline: auto 1px Highlight;
      outline: auto 1px -webkit-focus-ring-color;

      @include s.cos-focus;
    }
  }

  &--disabled {
    cursor: auto;
    pointer-events: none;
    user-select: none;
  }
}
