@mixin ion-modal-content-safe-area($spacing: theme('spacing.4')) {
  padding-bottom: calc(#{$spacing} + var(--safe-space-bottom));
}

@mixin ion-modal-content-safe-area-with-overlay(  $overlay-size: 66px,  $spacing: theme('spacing.4')) {
  padding-bottom: calc(    #{$overlay-size} + #{$spacing} + var(--safe-space-bottom)  );
}

/* stylelint-disable-next-line length-zero-no-unit */
@mixin ion-page-content-safe-area($extra-padding: 0px) {
  $navbar-size: 4.563rem;

  padding-bottom: calc(    #{$navbar-size} + #{$extra-padding} + var(--safe-space-bottom)  );
}

@mixin ion-toolbar-content-safe-area($spacing: theme('spacing.4')) {
  padding-top: calc(#{$spacing} + var(--safe-space-top));
}
