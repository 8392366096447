@use '../settings' as s;

@include s.narrower-than(theme('screens.lg')) {
  .bottom-sheet-open {
    .cdk-overlay-container {
      height: calc(100% - 3.938rem) !important;
      max-height: calc(100% - 3.938rem) !important;
      overflow: hidden;
    }
  }

  .cos-button.cos-button--sm.active {
    background-color: transparent;
    color: var(--base-content-low);
  }
}

/* Global Nav menu classes. Those are not intended to be used on any other menu */
.cos-global-header-menu-backdrop,.cos-global-header-menu-responsive-backdrop {
  & + .cdk-overlay-connected-position-bounding-box {
    margin-top: theme('spacing.2');

    .cdk-overlay-pane {
      clip-path: inset(0 -10px -10px -10px);
    }
  }
}

@include s.narrower-than(theme('screens.lg')) {
  .cos-global-header-menu-responsive-backdrop {
    & + .cdk-overlay-connected-position-bounding-box {
      margin-top: 0;

      .cdk-overlay-pane {
        position: absolute;
        top: s.$globalHeaderNavHeight !important;
        bottom: s.$mobileGlobalFooterNavHeight !important;
        left: 0 !important;
        right: 0 !important;
        border-radius: 0;
        margin-top: 0;

        .mat-menu-panel {
          border-radius: 0;
          box-shadow: none !important;
          &.cos-global-menu-panel {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
