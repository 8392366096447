@use '../settings' as s;

.mdc-tooltip {
  white-space: pre-line;
}

.mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: var(--tooltip__bg, var(--base-600));
  --mdc-plain-tooltip-supporting-text-color: var(--tooltip__color, var(--base-content-high));
  --mdc-plain-tooltip-container-shape: 0.25rem;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
}
